import Phaser from 'phaser'

const texts = {
  text1:{
    text: "We get data from\nalot of different\nsources",
    image: "char4",
    buttonText: "Continue"
  },
  text2:{
    text: "And we need help\ncatching the data\nin order to\ntransform it",
    image: "char4",
    buttonText: "Continue"
  },
  text3: {
    text: "This data for\ninstance, is data\nabout candy",
    image: "dataRose",
    buttonText: "Continue"
  },
  text4: {
    text: "And this is data\nconcerning cars",
    image: "dataGreen",
    buttonText: "Continue"
  },
  text5: {
    text: "Now get to work!",
    image: "char1",
    buttonText: "Start"
  }
}

let slide = 1
let endSlide = 5

class Intro2 extends Phaser.Scene {
  constructor(){
    super('Intro2')
  }
  create(data){
    this.add.image(250, 400, 'pc').setScale(0.28)
    this.mainImage = this.add.image(140, 350, texts.text1.image).setScale(0.24)
    this.mainText = this.add.text(180, 290, texts.text1.text, { fontSize: '20px', fill: '#000' });
    this.button = this.add.image(280, 400, 'button1')
      .setScale(2, 1)
      .setInteractive()
    .on('pointerdown', () => {
      if(slide == endSlide){
        this.scene.switch('Game')
      }
      else{
        this.textChange()
      }
    })
    this.buttonText = this.add.text(220, 388, texts.text1.buttonText, { fontSize: '24px', fill: '#FFF' })
  }

  textChange(){
    this.mainText.setText(texts[`text${slide + 1}`].text)
    this.mainImage.setTexture(texts[`text${slide + 1}`].image)
    this.buttonText.setText(texts[`text${slide + 1}`].buttonText)
    this.buttonText.x = this.button.x - 60
    slide += 1
  }
}

export default Intro2

// continuebutton width = 116
// startbutton width = 73
// 116 - 72 / 2 = 21.5