import Phaser from 'phaser'

const dataItems = [
  {
    image: 'dataGreen',
    color: '0x6a9591',
    title: 'EDC'
  },
  {
    image: 'dataRose',
    color: '0x996c7f',
    title: 'RBQM'
  },
  {
    image: 'dataBlueDark',
    color: '0x808ae5',
    title: 'IWRS'
  },
  {
    image: 'dataYellow',
    color: '0xff9d0b',
    title: 'RTSM'
  },
  {
    image: 'dataBlueLight',
    color: '0x547f9e',
    title: 'RTSM'
  },
  {
    image: 'dataGrey',
    color: '0x878c97',
    title: 'RTSM'
  },
]

class Game extends Phaser.Scene {
  constructor () {
      super('Game')
  }
  create(data) {
    this.keys = this.input.keyboard.createCursorKeys();

    this.funnelTop = this.physics.add.image(302, 742, 'funnelTop')
      .setScale(0.3)
      .setDepth(2)
      .setCollideWorldBounds(true)
    this.funnelTop.body.setAllowGravity(false)
    this.funnelTop.moveSpeed = 4

    this.funnelTube = this.physics.add.image(10, 770, 'funnelTube')
    .setScale(0.3)
    .setDepth(2)
    this.funnelTube.body.setAllowGravity(false)

    this.spawnTime = 1500

    this.score = 0
    this.scoreText = this.add.text(16, 16, `Score: ${this.score}`, { fontSize: '32px', fill: '#FFF' });

    this.end = 'You have\ncompleted\nthe level!'
    this.endText = this.add.text(150, 200, `${this.end}`, { fontSize: '32px', fill: '#FFF' });
    this.endText.visible = false
    this.succes = this.add.image(250, 400, 'char3').setScale(0.3)
    this.succes.visible = false
    this.createTanks()

    // create group for edges
    this.funnelEdgeLeft = this.physics.add.image(281, 742, 'colliderSprite')
    this.funnelEdgeLeft.body.setAllowGravity(false)
    this.funnelEdgeLeft.setRotation(4.71)
    this.funnelEdgeLeft.setScale(0.2, 0.3).refreshBody()
    this.funnelEdgeLeft.setSize(this.funnelEdgeLeft.height, this.funnelEdgeLeft.width, true)
    this.funnelEdgeLeft.setDepth(3)
    this.funnelEdgeLeft.setImmovable(true)
    this.funnelEdgeLeft.visible = false

    this.funnelEdgeRight = this.physics.add.image(322, 742, 'colliderSprite')
    this.funnelEdgeRight.body.setAllowGravity(false)
    this.funnelEdgeRight.setRotation(4.71)
    this.funnelEdgeRight.setScale(0.2, 0.3).refreshBody()
    this.funnelEdgeRight.setSize(this.funnelEdgeRight.height, this.funnelEdgeRight.width, true)
    this.funnelEdgeRight.setDepth(3)
    this.funnelEdgeRight.setImmovable(true)
    this.funnelEdgeRight.visible = false

    this.funnelCollider = this.physics.add.image(301, 740, 'colliderSprite')
    this.funnelCollider.body.setAllowGravity(false)
    this.funnelCollider.setScale(0.4, 0.5)
    this.funnelCollider.setImmovable(true)
    this.funnelCollider.visible = false

    this.botCollider = this.physics.add.image(400, 760, 'colliderSprite')
    this.botCollider.body.setAllowGravity(false)
    this.botCollider.setScale(8, 1)
    this.botCollider.setImmovable(true)
    this.botCollider.visible = false

    this.spawnTimer = this.time.addEvent({ delay: this.spawnTime, loop: true, callback: this.spawnData, callbackScope:this})

  }
  update(time, delta){
    const { keys:{left, right}, funnelTop, funnelTube, funnelEdgeLeft, funnelEdgeRight, funnelCollider } = this
    if(right.isDown){
      funnelTop.setX(funnelTop.x + funnelTop.moveSpeed)
      if(funnelTop.x > 475){
        funnelTop.x = 475
      }
      funnelTube.setX(funnelTop.x - 292)
      funnelEdgeLeft.setX(funnelTop.x - 20)
      funnelEdgeRight.setX(funnelTop.x + 20)
      funnelCollider.setX(funnelTop.x)
    }
    if(left.isDown){
      funnelTop.setX(funnelTop.x - funnelTop.moveSpeed)
      if(funnelTop.x < 25){
        funnelTop.x = 25
      }
      funnelTube.setX(funnelTop.x - 292)
      funnelEdgeLeft.setX(funnelTop.x - 20)
      funnelEdgeRight.setX(funnelTop.x + 20)
      funnelCollider.setX(funnelTop.x)
    }
  }
  spawnData(){
    let randItem = Math.floor(Math.random() * 3)
    let randPos = (Math.floor(Math.random() * 395) + 100)
    let dataItem = this.physics.add.image(randPos, 20, dataItems[this.randoItems[randItem]].image).setScale(0.09)
    .setCollideWorldBounds(true)
    dataItem.setBounce(0.3)
    dataItem.body.setCircle(70, 25)
    dataItem.value = this.randoItems[randItem]

    this.physics.add.collider(dataItem, this.botCollider, this.missedDataItem, null, this)
    this.physics.add.collider(dataItem, this.funnelEdgeLeft)
    this.physics.add.collider(dataItem, this.funnelEdgeRight)
    this.physics.add.collider(dataItem, this.funnelCollider, this.dataCatch, null, this)
  }
  missedDataItem(item, collider){
    const {amountFilled, amountNeeded} = this.amountToFill[`tankValue${item.value}`]
    if(amountFilled != amountNeeded){
      this.score -= 5
      this.scoreText.setText('Score: ' + this.score)
    }
    item.destroy()
  }
  dataCatch(item, collider){
    const targetItem = this[`tank${item.value}Rect`]
    const {amountToFill, amountFilled, amountNeeded} = this.amountToFill[`tankValue${item.value}`]

    if(amountFilled < amountNeeded){
      targetItem.height = targetItem.height + amountToFill
      targetItem.y = targetItem.y - amountToFill
      this.amountToFill[`tankValue${item.value}`].amountFilled += 1
      if(amountFilled + 1 == amountNeeded){
        this.score += 100
        this.scoreText.setText('Score: ' + this.score)
      } else {
        this.score += 10
        this.scoreText.setText('Score: ' + this.score)
      }
    } else {
      this.score -= 5
      this.scoreText.setText('Score: ' + this.score)
    }
    item.destroy()
    
    if(this.spawnTimer.delay > 600){
      this.spawnTimer.delay -= 50
    }
    this.checkLevelEnd(this.amountToFill)
  }

  checkLevelEnd(obj){
    let allTanksFilled = false
    for(const item in obj){
      const {amountNeeded, amountFilled} = obj[item]
      if(amountNeeded === amountFilled){
        allTanksFilled = true
      } else {
        allTanksFilled = false
        break
      }
    }
    if(allTanksFilled){
      this.endText.visible = true
      this.spawnTimer.remove(false)
      this.succes.visible = true
    }
  }

  createTanks(){
    let rectPlacements = [251, 401, 551]
    this.amountToFill = {}
    // create image to get height
    let refImage = this.add.image(0, 0, 'tank')
    refImage.visible = false
    const randoSet = new Set()
    while (randoSet.size < 3){
      randoSet.add(Math.floor(Math.random() * 4))
    }
    this.randoItems = [...randoSet]

    this.randoItems.forEach((item, index) => {
      this[`tank${item}Rect`] = this.add.rectangle(50, rectPlacements[index], 32, 0, dataItems[item].color)
      this[`tank${item}Rect`].value = `tankValue${item}`

      // randamount should always be a certain amount of needed hits
      let randAmount = Math.floor(Math.random() * 5)
      this.amountToFill = {...this.amountToFill, ...{[`tankValue${item}`]:{
          amountNeeded:randAmount +1, 
          amountToFill: refImage.height / (randAmount + 1), 
          amountFilled:0
        }}}
    })
    this.tank1 = this.add.image(33, 500, 'tank')
    this.tank2 = this.add.image(33, 350, 'tank')
    this.tank3 = this.add.image(33, 200, 'tank')
  }
}

export default Game