import Phaser from 'phaser'
let baseUrl = 'https://client-images-emillos.s3.eu-west-1.amazonaws.com/shadowrabbit/'
let baseUrl2 = 'https://client-images-emillos.s3.eu-west-1.amazonaws.com/nn/'

let font = {
    font: '16px monospace',
    fill: '#000'
}

class Preloader extends Phaser.Scene{
  constructor(){
    super('Preloader')
  }

  preload(){
    this.add.image(250, 400, 'pc').setScale(0.28)
    this.cog = this.add.image(250, 330, 'cog')

    /* var progressBox = this.add.graphics()
    progressBox.fillStyle(0x222222, 0.8)
    progressBox.fillRect(150, 390, 200, 30) */

    this.make.text({
        x: 180,
        y: 400,
        text: 'Loading...',
        style: font
    })

    var percentText = this.make.text({
        x: 300,
        y: 400,
        text: '0%',
        style: font
    })

    this.load.on('progress', function (value) {
        percentText.setText(parseInt(value * 100) + '%')
    })
    
    this.load.on('complete', function () {
        percentText.destroy()
    })

    this.load.image('funnelTop', `${baseUrl2}funnel_top.png`)
    this.load.image('funnelTube', `${baseUrl2}funnel_tube.png`)
    this.load.image('dataYellow', `${baseUrl2}dataIcon1.png`)
    this.load.image('dataBlueLight', `${baseUrl2}dataIcon2.png`)
    this.load.image('dataGrey', `${baseUrl2}dataIcon3.png`)
    this.load.image('dataGreen', `${baseUrl2}dataIcon4.png`)
    this.load.image('dataRose', `${baseUrl2}dataIcon5.png`)
    this.load.image('dataBlueDark', `${baseUrl2}dataIcon6.png`)
    this.load.image('colliderSprite',`${baseUrl}collider.png`)
    this.load.image('tank', `${baseUrl2}tank_test.png`)
    this.load.image('bulp', `${baseUrl2}bulp.png`)
    this.load.image('pc', `${baseUrl2}pc2.png`)    
    this.load.image('char1', `${baseUrl2}char1.png`)
    this.load.image('char2', `${baseUrl2}char2.png`)
    this.load.image('char3', `${baseUrl2}char3.png`)
    this.load.image('char4', `${baseUrl2}char4.png`)
    this.load.image('button1', `${baseUrl2}buttonGreenish.png`)
}
  
  create(){
    this.scene.switch('Intro1')
  }
}

export default Preloader