import Phaser from 'phaser'

class Intro1 extends Phaser.Scene {
  constructor(){
    super('Intro1')
  }
  create(data){
    this.add.image(250, 400, 'pc').setScale(0.28)
    this.add.image(155, 350, 'char1').setScale(0.24)
    this.add.text(215, 310, 'We need\nyour help', { fontSize: '32px', fill: '#000' });
    let button = this.add.image(280, 400, 'button1').setInteractive()
    .on('pointerdown', () => {
      this.scene.switch('Intro2')
    })
    this.add.text(265, 388, 'OK', { fontSize: '24px', fill: '#FFF' })
  }
}

export default Intro1