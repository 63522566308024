const Phaser = require('phaser')
let baseUrl2 = 'https://client-images-emillos.s3.eu-west-1.amazonaws.com/nn/'

class PrepreLoader extends Phaser.Scene{
  constructor(){
    super('PrepreLoader')
  }
  preload(){
    this.load.image('cog', `${baseUrl2}cog.gif`)
    this.load.image('pc', `${baseUrl2}pc2.png`)
  }
  create(){
    this.scene.switch('Preloader')
  }
}

export default PrepreLoader