const Phaser = require('phaser')
import Game from './game.js'
import Preloader from './preloader.js'
import Intro1 from './intro1.js'
import Intro2 from './intro2.js'
import prepreLoader from './prepreLoader'

const gameWidth = 500
const gameHeight = 800

const config = {
  type: Phaser.AUTO,
  width: gameWidth,
  height: gameHeight,
  parent: 'phaser',
  title: 'Test game',
  backgroundColor: '#44AABB',
  physics: {
    default: 'arcade',
    arcade: {
      //debug: true,
      gravity: { y: 400, x:1 }
    }
  },
  dom: {
    createContainer: true
  },
  input: {
    queue: true
  },
  scene: [prepreLoader, Preloader, Intro1, Intro2, Game]
}

new Phaser.Game(config);